import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Lounge, LoungeType } from '../models/app.models';

@Injectable({
  providedIn: 'root',
})
export class LoungeService {
  constructor(private http: HttpClient) {}

  getAllLoungesFromEvent(eventId: number | null): Observable<Lounge[]> {
    return this.http.get<Lounge[]>(environment.api + '/lounge/all/' + eventId);
  }

  getLoungeById(id: number | null, eventId: number | null): Observable<Lounge> {
    if (eventId) {
      return this.http.get<Lounge>(
        environment.api + `/lounge/detail/${id}/${eventId}`,
      );
    }

    return this.http.get<Lounge>(environment.api + `/lounge/${id}`);
  }

  getLoungeTypes(): Observable<LoungeType[]> {
    return this.http.get<LoungeType[]>(environment.api + '/loungeType');
  }

  createLounge(lounge: Lounge): Observable<any> {
    return this.http.post(environment.api + '/lounge', lounge).pipe(
      map((response: any) => response.id),
      catchError((error: HttpErrorResponse) =>
        throwError(
          () =>
            new Error(
              `Something bad happened; please try again later. Http-Response: ${error.status}`,
            ),
        ),
      ),
    );
  }

  updateLounge(lounge: Omit<Lounge, 'locationId'>) {
    return this.http
      .put(environment.api + '/lounge/update', lounge)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(
            () =>
              new Error(
                `Something bad happened; please try again later. Http-Response: ${error.status}`,
              ),
          ),
        ),
      );
  }

  checkInLounge(lounge: Lounge) {
    return this.http
      .put(environment.api + '/lounge/checkIn', lounge)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(
            () =>
              new Error(
                `Something bad happened; please try again later. Http-Response: ${error.status}`,
              ),
          ),
        ),
      );
  }

  deleteLounge(loungeId: number) {
    return this.http
      .delete(environment.api + `/lounge/${loungeId}`)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(
            () =>
              new Error(
                `Something bad happened; please try again later. Http-Response: ${error.status}`,
              ),
          ),
        ),
      );
  }
}
